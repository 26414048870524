.App {
    text-align: center;
}

.App-logo {
    height: 50px;
    pointer-events: none;
}

.App-header {
    min-height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.error-msg {
    color: #b91a1a;
}

.success-msg {
    color: #036003;
}
