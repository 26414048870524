.button-primary {
    padding: 10px;
    background-color: #3aaca9;
    color: white;
    border: 0;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
}

.button-primary:hover:enabled:not(.loading) {
    background-color: #2c8684;
    cursor: pointer;
}


.button-primary:disabled {
    opacity: 0.3;
    cursor: auto;
}

.button-primary.loading {
    background-color: transparent;
    cursor: auto;
}

.button-primary:active:enabled:not(.loading) {
    background-color: #2c8684;
    cursor: pointer;
    padding: 12px;
    margin: -2px 0;
}
